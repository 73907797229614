import { useState } from 'react';
import '../styles/AnimatedCard.css';
import Image from 'react-bootstrap/Image';
import { Card, Container } from 'react-bootstrap';

type Props = {
  static_image: string,
  animated_image: string,
  title: string,
  description: string,
  link?: string
}
const AnimatedCard = ({static_image, animated_image, title, description, link}:Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseClick = () => {
    if(link != null){
      window.location.replace(link);
    }
  }

  return (
    <Card
      className={`text-center animated-card ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
    >
      <div className="image-container">
        {/* Static Image */}
        <Card.Img
          src={static_image}
          alt="Static"
          className={`image-static ${isHovered ? 'fade-out' : ''}`}
        />
        {/* Animated GIF */}
        <Card.Img
          src={animated_image}
          alt="Animated"
          className={`image-animated ${isHovered ? 'fade-in' : ''}`}
        />
      </div>
      <Card.Body className="card-content">
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default AnimatedCard;
